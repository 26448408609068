import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Admin_Dashboard.css';
import FeedbackRequestForm from '../Send_Feedback_Request/Send_Feedback_Request'; 
import AddEmployeeTable from '../Employee-table/Employee-table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';


const AdminDashboard = ({ companyName }) => {
  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = useState({
    fullName: '',
    empId: '',
    dob: '',
    role: '',
    profilePic: localStorage.getItem('adminProfilePic') || './Images/Default.png', 
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);
  const [isImageUploadModalVisible, setIsImageUploadModalVisible] = useState(false); 
  const [selectedFile, setSelectedFile] = useState(null); 

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      const employeeId = localStorage.getItem('employee_id'); 

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/getEmployeeById?id=${employeeId}`);
        const data = await response.json();

        if (response.ok && data.statusCode === 200) {
          const { first_name, last_name, id, dob, role, profile_pic } = data.result;
          setEmployeeDetails({
            fullName: `${first_name} ${last_name}`,
            empId: id,
            dob: dob ? new Date(dob).toLocaleDateString() : 'N/A', 
            role: role || 'N/A',
            profilePic: profile_pic || './Images/Default.png',
          });

          
          localStorage.setItem('adminProfilePic', profile_pic || './Images/Default.png');
        } else {
          console.error('Error fetching employee details:', data.message);
        }
      } catch (error) {
        console.error('Error during API call:', error);
      }
    };

    fetchEmployeeDetails();
  }, []);

  const handleAddEmployeeClick = () => {
    navigate('/create-employee');
  };

  const handleFeedbackClick = () => {
    setIsFeedbackPopupVisible(true);
  };

  const handleCloseFeedbackPopup = () => {
    setIsFeedbackPopupVisible(false);
  };

  const handleProfilePicClick = () => {
    setIsImageUploadModalVisible(true); 
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]); 
  };

  const handleImageUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file first.");
      return;
    }

    const employeeId = localStorage.getItem('employee_id'); 

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('id', employeeId); 

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/uploadPhotoForEmployee`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      if (data.statusCode === 200) {
        toast.success("Image uploaded successfully");

        
        const uploadedPicUrl = data.result.profile_pic;
        localStorage.setItem('adminProfilePic', uploadedPicUrl);

       
        setTimeout(() => {
          window.location.reload(); 
        }, 1500); 
      } else {
        toast.error("Failed to upload image");
      }
    } catch (error) {
      toast.error("Error uploading image");
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="admin">
        <div className="admin-details">
          <div className="admin-profile">
            <img
              src={employeeDetails.profilePic} 
              className="admin-profile-pic"
              alt="profile_pic"
            />
            <CameraAltOutlinedIcon
              className="upload-admin-pic"
              onClick={() => setIsImageUploadModalVisible(true)}
             style={{ cursor: 'pointer' }} 
             />
            <div className="admin-del-container">
              <div className="admin-det-lables">
                <p>Full Name</p>
                <p>EMP ID</p>
                <p>DOB</p>
                <p>Role</p>
              </div>
              <div className="admin-det-columns">
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
              </div>
              <div className="admin-det-values">
                <p>{employeeDetails.fullName}</p>
                <p>{employeeDetails.empId}</p>
                <p>{employeeDetails.dob}</p>
                <p>{employeeDetails.role}</p>
              </div>
            </div>
          </div>
          <div className="admin-company-details">
            <div className="admin-company-details-pairs">
              <img src='./Images/send-feedback.png' className='feed-back-pics' alt="Feedback" onClick={handleFeedbackClick} />
            </div>
          </div>
        </div>
        <div className="admin-additions">
          <div className="admin-additions-header">
            <p className="admin-additions-header-head">Employee List</p>
            <div className="admin-addition-buttons">
              <button className="admin-addition-buttons-add-admin">
                <p className="para">Add Employee</p>
                <img
                  className="admin-addition-buttons-add-admin-pic"
                  src="./Images/add.png"
                  alt="Add Admin"
                  onClick={handleAddEmployeeClick}
                />
              </button>
              <div className="admin-addition-search">
                <input
                  className="admin-addition-search-input"
                  type="text"
                  placeholder=""
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="admin-addition-search-input-style-container">
                  <img
                    className="admin-addition-search-input-img"
                    src="./Images/search-icon.png"
                    alt="Search"
                  />
                </div>
              </div>
            </div>
          </div>
          <AddEmployeeTable searchTerm={searchTerm} />
        </div>
      </div>

      {isFeedbackPopupVisible && <FeedbackRequestForm onClose={handleCloseFeedbackPopup} />}

      {isImageUploadModalVisible && (
        <div className="image-upload-modal-overlay">
          <div className="image-upload-modal-content">
            <h3>Upload Profile Picture</h3>
            <input type="file" onChange={handleFileChange} />
            <button className="Image-upload-button" onClick={handleImageUpload}>Upload</button>
            <button className="Image-cancel-button" onClick={() => setIsImageUploadModalVisible(false)}>Cancel</button>
          </div>
        </div>
      )}


    </div>
  );
};

export default AdminDashboard;
